<template>
  <section class="coupon-setting" :v-loading="loading">
    <PageTitle
      title="設備模組參數設定"
      icon="chevron_left"
      style="padding-bottom: 24px"
      hideBtn
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <ResourceConfigSettings title="設備顯示名稱設定" :formData="formData" @edit="modal.name = true">
      <el-form-item label="顯示名稱" prop="displayName">
        <div class="text-[#5E5E5E]">{{ formData.displayName }}</div>
      </el-form-item>
    </ResourceConfigSettings>
    <ResourceConfigSettings title="設備預約設定" :formData="formData" @edit="modal.reservation = true">
      <el-form-item label="同時空檔設備分配規則" prop="assignRule">
        <div class="text-[#5E5E5E]">{{ renderRuleName }}</div>
      </el-form-item>
    </ResourceConfigSettings>

    <ResourceDialog
      title="設備顯示名稱設定"
      :visible="modal.name"
      @close="modal.name = false"
      @confirm="confirm"
    >
      <el-form ref="form" :model="formData">
        <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
          <p class="font-bold"><span class="text-danger">* </span>顯示名稱</p>
          <el-tooltip placement="right">
            <div slot="content">
              您可以自行設定設備在客人端顯示<br>的文字，至多 5 個字。例如：器<br>材、設備等
            </div>
            <span class="material-icons">help_outline</span>
          </el-tooltip>
        </div>
        <el-form-item prop="displayName">
          <el-input v-model="formData.displayName" :maxlength="5" show-word-limit />
        </el-form-item>
      </el-form>
    </ResourceDialog>
    <ResourceDialog
      title="編輯設備預約設定"
      :visible="modal.reservation"
      @close="modal.reservation = false"
      @confirm="confirm"
    >
      <el-form ref="form" :model="formData">
        <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
          <p class="font-bold"><span class="text-danger">* </span>同時空檔設備分配規則</p>
        </div>
        <el-form-item prop="assignRule">
          <el-select v-model="formData.assignRule" class="form-select">
            <el-option
              v-for="item in assignRuleConfig"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <p class="text-[#636363] text-[14px]">
          預約數優先：依照前後14天內預約數，較低者優先，相同則隨機。<br>
          不指定預約數優先：依照前後14天內來自不指定的預約數，較低者優先，相同則隨機。<br>
          排序優先：依照服務人員的排序，較低者優先。
        </p>
      </el-form>
    </ResourceDialog>
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import store from '@/store'
import { GetResourceConfig, UpdateResourceConfig } from '@/api/resource'
import { get } from 'lodash'
import { assignRuleConfig } from '@/config/resource'
import ResourceConfigSettings from './components/ResourceConfigSettings.vue'
import ResourceDialog from './components/ResourceDialog.vue'

export default defineComponent({
  name: 'ResourceSettings',
  components: {
    ResourceConfigSettings,
    ResourceDialog,
  },
  setup (props) {
    const loading = ref(false)
    const formData = reactive({
      displayName: '',
      assignRule: '',
    })
    const modal = reactive({
      name: false,
      reservation: false,
    })
    const shopId = computed(() => store.getters.shop)

    const confirm = async () => {
      await updateResourceConfig()
      modal.name = false
      modal.reservation = false
    }
    const renderRuleName = computed(() => {
      const option = assignRuleConfig.find((item) => item.value === formData.assignRule)
      if (option) {
        return option.label
      }
      return ''
    })
    const getResourceConfig = async () => {
      loading.value = true
      const [res, err] = await GetResourceConfig({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      formData.displayName = get(res, 'pageText.RESOURCE') || '設備'
      formData.assignRule = get(res, 'assignRule')
    }

    const updateResourceConfig = async () => {
      loading.value = true
      const [, err] = await UpdateResourceConfig({
        shopId: shopId.value,
        pageText: { RESOURCE: formData.displayName },
        assignRule: formData.assingRule,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功!')
      loading.value = false
    }

    onMounted(() => {
      getResourceConfig()
      console.log(assignRuleConfig)
    })
    return {
      loading,
      formData,
      modal,
      confirm,
      assignRuleConfig,
      renderRuleName,
    }
  },
})
</script>
<style lang="postcss" scoped>
.el-input, .el-select{
  @apply !w-full
}
::v-deep .form-select .el-input {
  @apply !w-full
}
</style>
